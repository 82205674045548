import { useEffect } from "react";
import { Link } from "react-router-dom";
import BgArea from "../components/BgArea";
import ContactForm from "../components/ContactForm";
import ServicesList from "../components/ServicesList";
function AsksuiteHotelChatbot() {
  useEffect(() => {
    document.title = "Asksuite Hotel Chatbot";
  }, []);
  return (
    <>
      <BgArea
        title1={`Asksuite Hotel Chatbot`}
        title2={`Services`}
        path={`/services`}
      />
      <div className="service-details pages pt-90 pb-50">
        <div className="container">
          <div className="row">
          <ServicesList/>

            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="service-main-details">
                    <div className="service-main-details-inner">
                      <div className="service-main-details-inner-thumb">
                        <img src="assets/images/services/s9.jpg" alt="" />
                      </div>
                      <div className="service-main-details-content-title pt-4 pb-3">
                        <h3>Asksuite Hotel Chatbot</h3>
                      </div>

                      <div className="service-main-details-content-text pb-4">
                        <p
                          style={{
                            margin: "0 0 15px",
                            fontFamily: "open sans, sans-serif",
                            lineHeight: "31px",
                          }}
                        >
                          Nowadays, customer service teams at hotels can easily
                          become overwhelmed; that is why every hotel needs a
                          Hotel Chatbot to reduce this pressure, handling all
                          the guests' frequent requests in rapid response times
                          and leaving staff to the trickier cases.
                        </p>

                        <p
                          style={{
                            margin: "0 0 15px",
                            fontFamily: "open sans, sans-serif",
                            lineHeight: "31px",
                          }}
                        >
                          Asksuite Chatbot will automatize your online customer
                          service in a way that guarantees to boost direct
                          bookings and conversion rates. It also enhances your
                          guests` booking journey and even encourages more
                          opportunities to up-sell and cross-sell
                        </p>

                        <p
                          style={{
                            margin: "0 0 15px",
                            fontFamily: "open sans, sans-serif",
                            lineHeight: "31px",
                          }}
                        >
                          Asksuite Chatbot is powered by artificial
                          intelligence; it can understand written communication,
                          interpret it and then respond appropriately on the
                          main communication channels: website chat (mobile and
                          desktop), messaging applications, and social media. It
                          helps you save time and answer relevant questions
                          about your potential guests' needs in more than 25
                          languages, 24/7, no vacation.
                        </p>
                        
                        <p
                          style={{
                            margin: "0 0 15px",
                            fontFamily: "open sans, sans-serif",
                            lineHeight: "31px",
                          }}
                        >
                          Never get lost handling hundreds of guests` messages; all you need is to use "Asksuite," the best chatbot solution for hotels.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*talk to experts section*/}
      <ContactForm />
    </>
  );
}

export default AsksuiteHotelChatbot;
